import { Card, Tooltip } from 'flowbite-react';
import { IoInformationCircle } from 'react-icons/io5';

import { TAX_FILING_TYPES } from '../../constants';
import { MaritalStatus, TaxFilingType } from '../../graphql/generated';
import { toDollars } from '../../util/currency.formatter';
import { FinoLink } from '../links/FinoLink';
import { FlyoutLink } from '../links/FlyoutLink';

interface ClientCardProps {
  name: string;
  role: string;
  occupation: string;
  state: string;
  income: number;
  spouse?: { firstName: string; lastName: string; id: number } | null;
  maritalStatus?: MaritalStatus | null;
  taxFilingType?: TaxFilingType | null;
  incomeTooltip?: string;
  clientId: number;
}

export default function ClientCard({
  name,
  role,
  occupation,
  state,
  income,
  spouse = null,
  maritalStatus,
  taxFilingType,
  incomeTooltip = 'Income information',
  clientId,
}: ClientCardProps) {
  const nameParts = name.split(' ');
  let initials = nameParts[0].charAt(0).toUpperCase();

  if (nameParts.length > 1) {
    initials += nameParts[1].charAt(0).toUpperCase();
  }

  return (
    <Card
      theme={{
        root: {
          children: 'px-3 py-3',
        },
      }}
      className="w-[311px] border rounded-lg shadow"
    >
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center space-x-3">
          <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full text-gray-600 font-semibold text-base">
            {initials}
          </div>
          <div>
            <h5 className="text-base font-semibold text-gray-900">{name}</h5>
            <p className="text-sm text-gray-500">{role}</p>
          </div>
        </div>
        <FlyoutLink
          className="text-primary-800 text-sm hover:underline font-semibold"
          flyoutId="upsert-client"
          params={`clientId=${clientId}`}
        >
          Edit
        </FlyoutLink>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-2">
        <div>
          <h6 className="text-xs font-medium text-gray-500">Occupation</h6>
          <p className="text-xs font-semibold text-gray-800 mt-1">
            {occupation === 'N/A'
              ? 'N/A'
              : occupation
                  .replace('_', ' ')
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ')}
          </p>
        </div>
        <div>
          <h6 className="text-xs font-medium text-gray-500 flex items-center">
            Income{' '}
            <Tooltip content={incomeTooltip} className="ml-0.5">
              <IoInformationCircle className="text-gray-400 ml-1" />
            </Tooltip>
          </h6>
          <p className="text-sm font-semibold text-gray-800 mt-1">{toDollars(income)}</p>
        </div>
        <div>
          <h6 className="text-xs font-medium text-gray-500 -ml-1">State</h6>
          <p className="text-sm font-semibold text-gray-800 mt-1 -ml-1">{state}</p>
        </div>
      </div>

      {spouse && (
        <div className="grid grid-cols-3 gap-2 mt-3">
          <div className="min-w-0">
            <h6 className="text-xs font-medium text-gray-500">Spouse</h6>
            <p className="text-sm font-semibold text-gray-800 mt-1 truncate">
              <FinoLink
                to={`/clients/${spouse.id}`}
                label={`${spouse.firstName} ${spouse.lastName}`}
                className="text-primary-900"
              />
            </p>
          </div>
          <div>
            <h6 className="text-xs font-medium text-gray-500">Marital Status</h6>
            <p className="text-sm font-semibold text-gray-800 mt-1">{maritalStatus}</p>
          </div>
          <div>
            <h6 className="text-xs font-medium text-gray-500">Tax Filing Type</h6>
            <p className="text-sm font-semibold text-gray-800 mt-1">
              {TAX_FILING_TYPES[taxFilingType as keyof typeof TAX_FILING_TYPES]}
            </p>
          </div>
        </div>
      )}
    </Card>
  );
}
