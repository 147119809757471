import { Spinner, TextInput, Table, Pagination } from 'flowbite-react';
import { ReactNode, useEffect, useMemo, useState, useRef } from 'react';
import { HiSearch } from 'react-icons/hi';

import { ClientListViewSortInput, InputMaybe } from '../../graphql/generated';
import useDebouncedState from '../../hooks/useDebouce';

interface RemainingRowsConfig {
  option: 'fill';
  numberOfRemainingRows: number;
  rowTemplate: () => JSX.Element;
}

interface RenderColumn<TRecordType> {
  render?: (
    text: any,
    record: TRecordType,
    index: number
  ) => JSX.Element | string | null | number | undefined;
}

export type ColumnsType<TRecordType> = (RenderColumn<TRecordType> & { [key: string]: any })[];

interface GridWithPromise<TRecordType> {
  dataSourcePromise: (
    skip?: number,
    take?: number,
    filter?: string,
    sortBy?: InputMaybe<ClientListViewSortInput | ClientListViewSortInput[]>
  ) => Promise<{ rows: TRecordType[]; totalCount: number }>;
  pagination?: boolean;
  reloadDependency: any;
  defaultPageSize?: number;
  searchVisible?: boolean;
  searchPlaceholder?: string;
  sortBy?: InputMaybe<ClientListViewSortInput | ClientListViewSortInput[]>;
  type?: undefined;
}

interface GridWithoutPromise<TRecordType> {
  rows: TRecordType[];
  type: 'no-promise';
}

type Props<TRecordType> = {
  columns: any; // ColumnsType<TRecordType>;
  emptyText?: (filter?: string) => ReactNode;
  remainingRowsConfig?: RemainingRowsConfig;
  onRowClick?: (row: TRecordType, rowIndex: number) => undefined;
  sortBy?: InputMaybe<ClientListViewSortInput | ClientListViewSortInput[]>;
  hideFooter?: boolean;
  rowClassName?: string;
  tableHeadingLabel?: string;
  ButtonComponent?: ReactNode;
  onButtonClick?: () => void;
} & (GridWithPromise<TRecordType> | GridWithoutPromise<TRecordType>);

export const FinologyTable = <TRecordType extends object = any>({
  columns,
  emptyText,
  remainingRowsConfig,
  onRowClick,
  hideFooter = false,
  tableHeadingLabel,
  ...props
}: Props<TRecordType>) => {
  const [filterValue, debouncedFilterValue, setFilterValue] = useDebouncedState<string | undefined>(
    undefined,
    250
  );
  const [dataRows, setDataRows] = useState<TRecordType[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, debouncedPageValue, setPageValue] = useDebouncedState<number>(1, 50);

  const loadInProgress = useRef(false);
  const previousReloadDependency = useRef(
    props.type == undefined ? props.reloadDependency : undefined
  );

  const shouldLoad = useMemo(
    () => {
      if (props.type == 'no-promise') return false;

      if (loadInProgress.current) return false;

      if (previousReloadDependency.current != props.reloadDependency) {
        previousReloadDependency.current = props.reloadDependency;

        return true;
      }

      return false;
    },
    props.type == undefined ? [props.reloadDependency] : [props.rows]
  );

  const [pageSize, debouncedPageSizeValue, setPageSize] = useDebouncedState<number>(
    props.type == undefined ? props.defaultPageSize || 10 : props.rows.length,
    50
  );

  useEffect(
    () => {
      if (props.type != 'no-promise') return;
      setDataRows(props.rows);
      setTotalCount(props.rows.length);
    },

    props.type == 'no-promise' ? [props.rows] : []
  );

  useEffect(() => {
    async function load() {
      if (props.type != undefined) return;

      setIsLoading(true);

      loadInProgress.current = true;

      const result = await props.dataSourcePromise(
        debouncedPageSizeValue && debouncedPageValue
          ? debouncedPageSizeValue * (debouncedPageValue - 1)
          : undefined,
        debouncedPageSizeValue,
        debouncedFilterValue,
        props.sortBy
      );

      loadInProgress.current = false;

      setDataRows(result.rows);
      setTotalCount(result.totalCount);

      setIsLoading(false);
    }

    load();
  }, [debouncedFilterValue, debouncedPageValue, debouncedPageSizeValue, shouldLoad, props.sortBy]);

  let rows = dataRows;

  if (remainingRowsConfig?.option === 'fill' && remainingRowsConfig.numberOfRemainingRows > 0) {
    columns = columns?.map((column: any, columnIndex: any) => {
      const newOnCell = (data: TRecordType, index?: number) => {
        if ((data as any).dummyRecord) {
          if (columnIndex == 0) return { colSpan: columns!.length };
          else return { colSpan: 0 };
        }

        if (column.onCell) return column.onCell(data, index);

        return {
          colSpan: 1,
        };
      };

      const newRender = (value: any, record: TRecordType, index: number) => {
        if ((record as any).dummyRecord) {
          return remainingRowsConfig.rowTemplate();
        }

        if (column.render) return column?.render(value, record, index);

        return null;
      };

      const newColumn = {
        ...column,
        onCell: newOnCell,
        render: newRender,
        colSpan: 1,
      };

      return newColumn;
    });

    rows = rows.concat([
      {
        dummyRecord: true,
        id: -1,
      } as any,
    ]);
  }

  return (
    <div className="border border-t-1 border-b-0 border-x-0 border-gray-200 rounded-md">
      <Table hoverable={rows.length > 0}>
        {tableHeadingLabel && (
          <Table.Head className="bg-white">
            <Table.HeadCell className="bg-white">
              <div className="text-lg font-semibold text-gray-900 py-1">{tableHeadingLabel}</div>
            </Table.HeadCell>
            <Table.HeadCell className="bg-white" align="right" colSpan={columns.length - 1}>
              <div className="flex gap-2 justify-end">
                {props.type == undefined && props.searchVisible ? (
                  <TextInput
                    icon={HiSearch}
                    className="w-80"
                    placeholder={props.searchPlaceholder}
                    value={filterValue}
                    onChange={(e: any) => {
                      setFilterValue(e.target.value);
                    }}
                  />
                ) : null}
                {props.ButtonComponent || null}
              </div>
            </Table.HeadCell>
          </Table.Head>
        )}

        <Table.Head>
          <>
            {columns.map((c: any) => (
              <Table.HeadCell
                className={`py-3.5 bg-gray-50 ${
                  tableHeadingLabel ? 'border-y' : 'border-b'
                } border-gray-200 text-gray-500`}
                key={c.key}
              >
                {c.title}
              </Table.HeadCell>
            ))}
          </>
        </Table.Head>
        <Table.Body className="divide-y">
          {isLoading ? (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>
                <div className="flex flex-col items-center">
                  <Spinner size="xl" />
                </div>
              </Table.Cell>
            </Table.Row>
          ) : rows.length ? (
            rows.map((row: any, rowIndex: number) => {
              return (
                <Table.Row
                  onClick={onRowClick ? () => onRowClick(row, rowIndex) : undefined}
                  key={rowIndex}
                  className={props.rowClassName}
                >
                  {columns.map((c: any, columnIndex: number) => (
                    <Table.Cell className={c.className} colSpan={c.colSpan} key={c.key}>
                      <span
                        className={
                          rowIndex === rows.length - 1
                            ? 'text-gray-700 font-bold text-sm'
                            : 'text-gray-700 text-sm font-medium'
                        }
                      >
                        {c.render ? c.render(row[c.dataIndex], row, rowIndex) : row[c.dataIndex]}
                      </span>
                    </Table.Cell>
                  ))}
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>
                <div className="flex flex-col items-center gap-8 justify-center py-8 text-gray-500">
                  {emptyText ? emptyText(filterValue) : 'No records found'}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {props.type == undefined &&
      (props.pagination || props.pagination === undefined) &&
      totalCount > 0 ? (
        <div className={`flex flex-col mt-4 gap-2 items-start text-gray-500`}>
          {Math.ceil(totalCount / pageSize) > 1 ? (
            <Pagination
              currentPage={page}
              totalPages={Math.ceil(totalCount / pageSize)}
              onPageChange={(newPage) => setPageValue(newPage)}
            />
          ) : null}
          {!hideFooter && (
            <div className=" text-xs font-medium">
              Result: {(page - 1) * pageSize + 1} - {(page - 1) * pageSize + rows.length} of{' '}
              {totalCount} items.
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
